<template>
  <!-- 剧集抽屉 -->
  <a-drawer
    :title="id ? '编辑活动' :'创建活动'"
    width="700"
    :closable="false"
    :visible="visible"
    @close="onClose"
    :footer-style="{ textAlign: 'right' }"
  >
    <a-form
      ref="RefForm"
      :model="formState"
      :label-col="{ span: 4 }"
      :wrapper-col="{ span: 16 }"
    >
      <!-- 活动类型 -->
      <a-form-item
        label="活动类型"
        name="type"
        :rules="[{ required: true, message: '请选择' }]"
      >
        <a-radio-group class="form-input" v-model:value="formState.type">
          <a-radio v-for="item in ActivityTypes" :value="item.value">{{ item.text }}</a-radio>
        </a-radio-group>
      </a-form-item>
      <!-- 活动标题 -->
      <a-form-item
        label="活动标题"
        name="title"
        :rules="[{ required: true, message: '请输入' }]"
      >
        <a-input
          placeholder="请输入"
          v-model:value="formState.title"
        />
      </a-form-item>
      <!-- 虚拟币数量 -->
      <a-form-item
        v-if="formState.type === 1 || formState.type === 3"
        label="虚拟币数量"
        name="coin"
        :rules="[{ required: true, validator: validator_coin }]"
      >
        <a-input
          placeholder="请输入"
          v-model:value="formState.coin"
        />
      </a-form-item>
      <!-- 会员天数 -->
      <a-form-item
        v-if="formState.type === 2 || formState.type === 4"
        label="会员天数"
        name="days"
        :rules="[{ required: true, validator: validator_coin }]"
      >
        <a-input
          placeholder="请输入"
          v-model:value="formState.days"
        />
      </a-form-item>
      <!-- 价格 -->
      <a-form-item
        v-if="formState.type !== 3 && formState.type !== 4"
        label="价格"
        name="money"
        :rules="[{ required: true, validator: validator_money }]"
      >
        <a-input
          placeholder="请输入"
          v-model:value="formState.money"
        />
      </a-form-item>
      <!-- 原价 -->
      <a-form-item
        label="原价"
        name="original_price"
        :rules="[{ required: true, validator: validator_money }]"
      >
        <a-input
          placeholder="请输入"
          v-model:value="formState.original_price"
        />
      </a-form-item>
      <!-- 活动时间 -->
      <a-form-item
        label="活动时间"
        name="time"
        :rules="[{ required: true, message: '请选择' }]"
      >
        <a-range-picker
          v-model:value="formState.time"
          :format="dateFormat"
        />
      </a-form-item>
      <!-- 限购周期 -->
      <a-form-item
        label="限购周期"
        name="activity_cycle"
        :rules="[{ required: true, message: '请选择' }]"
      >
        <a-radio-group class="form-input" v-model:value="formState.activity_cycle">
          <a-radio :value="1">整个活动</a-radio>
          <a-radio :value="2">每天</a-radio>
        </a-radio-group>
      </a-form-item>
      <!-- 限购次数 -->
      <a-form-item
        label="限购次数"
        name="times"
        :rules="[{ required: true, message: '请选择' }]"
      >
        <a-select
          v-model:value="formState.times"
          placeholder="请选择"
          :getPopupContainer="(triggerNode) => triggerNode.parentNode"
        >
          <a-select-option
            v-for="item in 10"
            :key="item"
            :value="item"
          >
            {{ item }}
          </a-select-option>
        </a-select>
      </a-form-item>
      <!-- banner -->
      <a-form-item
        label="banner"
        name="img_urls"
        :rules="[{ required: true, message: '请上传' }]"
      >
        <Upload
          accept=".png,.jpg,.gif"
          uploadSource="NewFilmDrawer"
          :fileNumber="5"
          :imgSizeMode="3"
          :imgSizeWidth="670"
          :imgSizeHeight="332"
          imgSizeError="图片尺寸为 670 * 332"
          :beforeUploadPro="(file) => { return beforeUploadPro(file, 'img_urls') }"
          :fileList="formState.img_urls"
          :uploadBucket="$pub.BUCKET_PUB()"
        ></Upload>
        <div class="form-upload-hint">支持.png .jpg .gif，尺寸为 670x332</div>
        <div class="form-upload-list" v-if="formState.img_urls.length">
          <div
            class="form-upload-item"
            v-for="(item, index) in formState.img_urls"
            :key="index"
          >
            <ImageView
              class="upload-image upload-image-horizontal"
              :url="$pub.CDN_URL(item.url)"
              :fileJson="item"
              @delete="touchDeleteCover('img_urls', index)"
            >
            </ImageView>
          </div>
        </div>
      </a-form-item>
      <!-- 活动说明 -->
      <a-form-item
        label="活动说明"
        name="activity_description"
        :rules="[{ required: true, message: '请输入' }]"
      >
        <a-textarea
          class="desc-textarea"
          placeholder="请输入"
          v-model:value="formState.activity_description"
          :auto-size="{ minRows: 5, maxRows: 5 }"
        />
      </a-form-item>
    </a-form>
    <!-- 尾部 -->
    <template #footer>
      <a-button style="margin-right: 8px" @click="onClose">取消</a-button>
      <a-button type="primary" @click="touchSubmit">确定</a-button>
    </template>
    <!-- 加载 -->
    <Loading :loading="isLoading"></Loading>
  </a-drawer>
</template>

<script setup>
import { ref, reactive, defineExpose, nextTick } from 'vue'
import { message } from 'ant-design-vue'
import Upload from '@/components/Upload'
import Loading from '@/components/Loading'
import ImageView from '@/components/ImageView'
import { activitySave } from '@/api/copyright'
import { ActivityTypes } from '@/utils/constantList'
import Pub from '@/utils/public'
import dayjs from 'dayjs'

// emit
const emit = defineEmits(['success'])
// Form实例
const RefForm = ref(null)
// 日期format
const dateFormat = 'YYYY-MM-DD'
// 抽屉展示状态
let visible = ref(false)
// 加载
let isLoading = ref(false)
// id
let id = ref(null)
// 详情
let detail = ref({})
// 表单
let formState = reactive({
  // 活动类型
  type: 1,
  // 活动标题
  title: undefined,
  // 虚拟币数量
  coin: undefined,
  // 会员天数
  days: undefined,
  // 价格
  money: undefined,
  // 原价
  original_price: undefined,
  // 活动时间
  time: undefined,
  // 活动开始时间
  start_time: undefined,
  // 活动结束时间
  end_time: undefined,
  // 限购周期
  activity_cycle: 1,
  // 限购次数
  times: undefined,
  // banner图
  img_urls: [],
  img_url: undefined,
  // 活动说明
  activity_description: undefined
})


// 打开抽屉
function showDrawer (params) {
  // 展开
  detail.value = params
  visible.value = true
  // 赋值
  nextTick(() => {
    if (params) {
      // id
      id.value = params.id
      formState.type = params.type
      formState.title = params.title
      formState.coin = params.coin
      formState.days = params.days
      formState.money = params.money
      formState.original_price = params.original_price
      formState.time = [dayjs(params.start_time), dayjs(params.end_time)]
      formState.start_time = params.start_time
      formState.end_time = params.end_time
      formState.activity_cycle = params.activity_cycle
      formState.times = params.times
      formState.img_urls = [{
        url: params.img_url
      }]
      formState.img_url = params.img_url
      formState.activity_description = params.activity_description
    }
  })
}

// 关闭抽屉
function onClose () {
  if (!isLoading.value) {
    // 重置
    RefForm.value.resetFields()
    formState.coin = undefined
    formState.days = undefined
    formState.money = undefined
    formState.original_price = undefined
    id.value = undefined
    visible.value = false
  }
}

// 准备上传（图片）
function beforeUploadPro (file, key) {
  if (!file.type.includes('image')) {
    message.error('请上传正确的图片类型文件')
    return false
  }
  // 清空列表
  if (key) { formState[key] = [] }
  // 允许
  return true
}

// 移除封面
function touchDeleteCover (coverKey, index) {
  formState[coverKey].splice(index, 1)
}

// 提交
function touchSubmit () {
  RefForm.value.validate().then(() => {
    isLoading.value = true
    formState.start_time = formState.time[0].format(dateFormat)
    formState.end_time = formState.time[1].format(dateFormat)
    formState.img_url = formState.img_urls[0].url
    const params = {
      id: id.value,
      ...formState
    }
    console.log(params)
    activitySave(params).then(res => {
      isLoading.value = false
      const { code, msg, data } = res
      if (code === 0) {
        if (id.value) {
          message.success('更新成功')
        } else {
          message.success('创建成功')
        }
        onClose()
        // 更新父组件
        emit('success')
      } else {
        message.error(res.message || msg)
      }
    })
  })
}

// 校验虚拟币数量
function validator_coin (rule, value) {
  if (!value) {
    return Promise.reject('请输入')
  } else if (!Pub.REG_IS_INTEGER(value)) {
    return Promise.reject('必须为正整数')
  }
  return Promise.resolve()
}
// 校验价格
function validator_money (rule, value) {
  if (!value) {
    return Promise.reject('请输入')
  } else if (!Pub.REG_IS_INTEGER_OR_FLOAT(value)) {
    return Promise.reject('必须为正数，且最多保留两位小数')
  }
  return Promise.resolve()
}

// 暴露出去
defineExpose({
  showDrawer
})

</script>

<style lang="less" scoped>
.a-link {
  margin-left: 8px;
}
.form-item-clear {
  margin-bottom: 0;
}
.form-upload-hint {
  padding-top: 6px;
  color: #8D8D8D;
}
.form-upload-list {
  display: flex;
  flex-wrap: wrap;
  margin-left: -10px;
}
.image-view.form-upload-item {
  margin-top: 10px;
  margin-left: 10px;
  position: relative;
  width: 118px;
  height: 148px;
  border-radius: 4px;
  overflow: hidden;
  img {
    display: inline-block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  &.horizontal {
    width: 148px;
    height: 118px;
  }
}
.upload-item-select {
  position: absolute;
  right: 8px;
  bottom: 8px;
}
.upload-item-delete {
  position: absolute;
  right: 8px;
  top: 8px;
  font-size: 18px;
  color: #8D8D8D;
  cursor: pointer;
  opacity: 0;
}
.form-upload-item:hover .upload-item-delete {
  opacity: 1;
}
.date-range {
  color: #8D8D8D;
  padding-left: 100px;
  margin-top: -15px;
}
.form-upload-hint {
  padding-top: 6px;
  color: #8D8D8D;
}
.form-upload-list {
  display: flex;
  flex-wrap: wrap;
  margin-left: -10px;
}
.form-upload-item {
  margin-top: 10px;
  margin-left: 10px;
  width: 118px;
}
.image-view.upload-image {
  height: 148px;
}
.image-view.upload-image-horizontal {
  width: 208px;
  height: 124px;
}
.desc-textarea {
  word-wrap: break-word;
  white-space: normal;
  word-break: break-all;
}
</style>