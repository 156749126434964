<template>
  <!-- 配置模板 -->
  <a-breadcrumb>
    <a-breadcrumb-item>运营管理</a-breadcrumb-item>
    <a-breadcrumb-item>活动管理</a-breadcrumb-item>
  </a-breadcrumb>
  <div class="content">
    <div class="tool-view">
      <!-- 活动类型 -->
      <span class="tool-title">活动类型：</span>
      <a-select
        class="tool-input"
        v-model:value="activitiesTypeModel"
        placeholder="请选择"
        :getPopupContainer="(triggerNode) => triggerNode.parentNode"
      >
        <a-select-option :value="0">全部</a-select-option>
        <a-select-option v-for="item in ActivityTypes" :key="item.value" :value="item.value">{{ item.text }}</a-select-option>
      </a-select>
      <!-- 活动状态 -->
      <span class="tool-title">活动状态：</span>
      <a-select
        class="tool-input"
        v-model:value="activitiesStatusModel"
        placeholder="请选择"
        :getPopupContainer="(triggerNode) => triggerNode.parentNode"
      >
        <a-select-option :value="0">全部</a-select-option>
        <a-select-option :value="1">未开始</a-select-option>
        <a-select-option :value="2">进行中</a-select-option>
        <a-select-option :value="3">已结束</a-select-option>
      </a-select>
      <!-- 操作 -->
      <a-button type="primary" @click="touchSearch">查询</a-button>
      <a-button style="margin-left: 16px;" @click="touchReset">重置</a-button>
      <div style="flex: 1;"></div>
      <!-- 其他操作 -->
      <a-button type="primary" @click="add"><plus-outlined />创建活动</a-button>
    </div>
    <div class="table-box">
      <a-table
        :data-source="dataSource"
        :columns="columns"
        :pagination="pagination"
        :row-key="record => record.id"
        :loading="isLoading"
        @change="handleTableChange"
      >
        <template #bodyCell="{ column, record }">
          <!-- 活动类型 -->
          <template v-if="column.key === 'type'">
            {{ (ActivityTypes.find(item => item.value === record[column.key]) || {}).text || '-' }}
          </template>
          <!-- 活动类型 -->
          <template v-if="column.key === 'start_time'">
            {{ `${record.start_time} ~ ${record.end_time}` }}
          </template>
          <!-- 操作 -->
          <template v-if="column.key === 'operation'">
            <a class="operation-item" @click="touchEdit(record)">编辑</a>
            <a class="operation-item" @click="touchCopy(record)">复制链接</a>
          </template>
        </template>
      </a-table>
    </div>
    <!-- 新增、编辑弹窗 -->
    <Add ref="RefAdd" @success="getData" />
    <!-- 新增、编辑弹窗 -->
    <Copy ref="RefCopy" />
  </div>
</template>

<script setup>
import { ref, reactive, nextTick, onBeforeMount } from 'vue'
import { message, Modal } from 'ant-design-vue'
import Add from './components-activities/Add'
import Copy from './components-activities/Copy'
import { ActivityTypes } from '@/utils/constantList'
import { activityList } from '@/api/copyright'

// 新增
let RefAdd = ref(null)
// 拷贝
let RefCopy = ref(null)
// 数据源
let dataSource = ref(null)
// 活动类型
let activitiesTypeModel = ref(undefined)
let activitiesType = ref(undefined)
// 活动状态
let activitiesStatusModel = ref(undefined)
let activitiesStatus = ref(undefined)
// 加载
let isLoading = ref(false)
// 表头
let columns = reactive([
  {
    title: '活动ID',
    dataIndex: 'id',
    key: 'id'
  },
  {
    title: '活动名称',
    dataIndex: 'title',
    key: 'title'
  },
  {
    title: '活动类型',
    dataIndex: 'type',
    key: 'type',
  },
  {
    title: '活动内容',
    dataIndex: 'content',
    key: 'content'
  },
  {
    title: '活动时间',
    dataIndex: 'start_time',
    key: 'start_time'
  },
  {
    title: '活动状态',
    dataIndex: 'status_name',
    key: 'status_name'
  },
  {
    title: '创建人',
    dataIndex: 'admin_name',
    key: 'admin_name'
  },
  {
    title: '操作',
    dataIndex: 'operation',
    key: 'operation'
  }
])
// 分页
let pagination = reactive({
  total: 0,
  current: 1,
  pageSize: 10,
  showSizeChanger: true
})

// 钩子函数 - onBeforeMount
onBeforeMount(() => {
  getData()
})

// 表格change
function handleTableChange (p, filters, sorter) {
  pagination.current = p.current
  pagination.pageSize = p.pageSize
  getData()
}

// 查询
function touchSearch () {
  // 调整参数
  activitiesType.value = activitiesTypeModel.value
  activitiesStatus.value = activitiesStatusModel.value
  pagination.current = 1
  // 获取列表
  getData()
}

// 重置
function touchReset () {
  activitiesTypeModel.value = undefined
  activitiesType.value = undefined
  activitiesStatusModel.value = undefined
  activitiesStatus.value = undefined
  // 初始化获取
  getData()
}

// 请求分页数据
function getData () {
  isLoading.value = true
  const params = {
    type: activitiesType.value,
    status: activitiesStatus.value,
    page: pagination.current,
    page_size: pagination.pageSize
  }
  activityList(params).then(res => {
    isLoading.value = false
    const { code, msg, data } = res
    if (code === 0) {
      dataSource.value = data.data
      pagination.total = data.total
    } else {
      message.error(res.message || msg)
    }
  })
}

// 新增弹窗
function add () {
  RefAdd.value.showDrawer()
}

// 编辑
function touchEdit (record) {
  RefAdd.value.showDrawer(record)
}

// 拷贝
function touchCopy (record) {
  RefCopy.value.showDrawer(record)
}
</script>

<style lang="less" scoped>
.content {
  padding: 24px 0;
  .tool-view {
    display: flex;
    align-items: center;
    margin-bottom: 16px;
    .tool-input {
      width: 150px;
      margin-right: 16px;
    }
  }
  .operation-item {
    margin-right: 8px;
  }
}

</style>